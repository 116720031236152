import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  Output,
  ViewChild,
} from '@angular/core';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { BaseComponent } from '../../../base/base.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'new-lottie',
  templateUrl: './new-lottie.component.html',
  styleUrls: ['./new-lottie.component.scss'],
})
export class NewLottieComponent extends BaseComponent {
  @ViewChild('lottie') public base: LottieComponent;

  @Input() options: AnimationOptions | null;

  @Output() animationEnd: EventEmitter<any> = new EventEmitter();
  @Output() domLoaded: EventEmitter<any> = new EventEmitter();

  private animationItem: AnimationItem;

  constructor(private ngZone: NgZone) {
    super();
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  public onComplete(event: any) {
    this.animationEnd.emit(event);
  }

  public onDomLoaded(): void {
    this.domLoaded.emit();
  }

  public stop(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.stop();
    });
  }

  public play(): void {
    this.ngZone.runOutsideAngular(() => {
      this.animationItem.play();
    });
  }
}
