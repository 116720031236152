import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // add baseUrl to url req if api not include 'http'

    if (req.url.includes('clearCache')) {
      req = req.clone({
        setHeaders: {
          'Clear-Site-Data': ['cache'],
        },
      });

      return next.handle(req);
    }

    // check if path include '/assets' then set cache to true and set cache time to 1 hour
    if (req.url.includes('/assets')) {
      req = req.clone({
        setParams: {
          cache: 'true',
          cacheTime: '3600',
        },
      });
    }

    return next.handle(req);
  }
}
