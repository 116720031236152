import { Injectable } from '@angular/core';
import { Resolve, Router } from '@angular/router';
import {
  HttpRoomService,
  HttpUserService,
  SpinnerService,
  StorageService,
} from '@common-ui/emeraude-shared';

@Injectable()
export class UserProfileResolver implements Resolve<any> {
  constructor(
    private readonly router: Router,
    private readonly storageService: StorageService,
    private readonly httpUserService: HttpUserService,
    private readonly httpRoomService: HttpRoomService,
    private readonly spinnerService: SpinnerService
  ) {}

  async resolve() {
    try {
      const user = await this.httpUserService.getById().toPromise();
      this.storageService.user.set(user);
      const roomId = user.currentRoomId;
      if (roomId) {
        const room = await this.httpRoomService.getRoom(roomId).toPromise();
        this.storageService.room.set(room);
      }
      return user;
    } catch (error) {
      this.router.navigateByUrl('/');
      this.spinnerService?.requestEnded?.();
      throw error;
    }
  }
}
