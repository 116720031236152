import { NgModule } from '@angular/core';
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import Lottie from 'lottie-web';
import { NewLottieComponent } from './components/new-lottie.component';

@NgModule({
  imports: [
    LottieModule.forRoot({ player: () => Lottie }),
    LottieCacheModule.forRoot(),
  ],
  declarations: [NewLottieComponent],
  exports: [NewLottieComponent],
})
export class NewLottieModule {}
