import { Component } from '@angular/core';
import { injectImages } from '@common-ui/emeraude-assets';
import { FormLayoutComponent } from '@common-ui/emeraude-components';
import {
  EmeraudeTranslationDirective,
  EmeraudeTranslationPipe,
} from '@common-ui/emeraude-shared';

import { AsyncPipe, NgIf } from '@angular/common';
import { AppVersionCheckService } from './app-version-check.service';
import { ButtonComponent } from '../button/button.component';
import { LayoutService } from '../../shared/service/layout.service';

@Component({
  selector: 'emeraude-outdated-version',
  standalone: true,
  imports: [
    ButtonComponent,
    EmeraudeTranslationDirective,
    EmeraudeTranslationPipe,
    NgIf,
    FormLayoutComponent,
    AsyncPipe,
  ],
  templateUrl: './emeraude-outdated-version.component.html',
  styleUrls: ['./emeraude-outdated-version.component.scss'],
})
export class EmeraudeOutdatedVersionComponent {
  logoImg = injectImages().get('emeraudeLogo');
  public readonly location = location;
  public isAppVersionChanged$ =
    this.appVersionCheckService.isAppVersionChanged$;

  constructor(
    public readonly layoutService: LayoutService,
    private readonly appVersionCheckService: AppVersionCheckService
  ) {}
}
