import { Directive, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { SubscriptionCollection } from './subscription-collection';

@Directive()
export abstract class Subscribable implements OnDestroy {
  protected subscriptionCollection = new SubscriptionCollection();

  public ngOnDestroy() {
    this.subscriptionCollection.clear();
  }

  protected subscribe<T>(
    observable: Observable<T>,
    handler?: (data: T) => void
  ) {
    this.subscriptionCollection.add(observable, handler);
  }

  protected addSubscription(...subscriptions: Array<Subscription>) {
    this.subscriptionCollection.addItems(subscriptions);
  }
}
