import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy } from '@angular/core';
import { EmeraudeTranslationDirective } from '@common-ui/emeraude-shared';
import { BehaviorSubject } from 'rxjs';

export type ViewportOrientation = 'portrait' | 'landscape';

@Component({
  selector: 'thales-365-device-orientation-match',
  standalone: true,
  imports: [CommonModule, EmeraudeTranslationDirective],
  templateUrl: './device-orientation-match.component.html',
  styleUrls: ['./device-orientation-match.component.scss'],
})
export class DeviceOrientationMatchComponent implements OnDestroy {
  @Input() set requiredOrientation(value: ViewportOrientation) {
    this._setRequiredDeviceOrientation(value);
  }
  get requiredOrientation(): ViewportOrientation {
    return this._expectedOrientation;
  }

  public shouldDisplayContainer$ = new BehaviorSubject<boolean>(false);

  private _mediaQueryList: MediaQueryList;
  private _expectedOrientation: ViewportOrientation;

  private _deviceOrientationChanged = (e: MediaQueryListEvent): void => {
    this._checkDeviceOrientation(
      e.matches ? 'portrait' : 'landscape',
      this._expectedOrientation
    );
  };

  private _checkDeviceOrientation(
    currentViewportOrientation: ViewportOrientation,
    expectedOrientation: ViewportOrientation
  ): void {
    this.shouldDisplayContainer$.next(
      currentViewportOrientation &&
        expectedOrientation &&
        currentViewportOrientation !== expectedOrientation
    );
  }

  private _setRequiredDeviceOrientation(value: ViewportOrientation) {
    if (!value) {
      return;
    }

    if (!this._mediaQueryList) {
      this._mediaQueryList = window.matchMedia('(orientation: portrait)');
    }

    this._checkDeviceOrientation(
      this._mediaQueryList.matches ? 'portrait' : 'landscape',
      value
    );

    if (this._expectedOrientation) {
      this._mediaQueryList?.removeEventListener(
        'change',
        this._deviceOrientationChanged
      );
    }

    this._mediaQueryList.addEventListener(
      'change',
      this._deviceOrientationChanged
    );
    this._expectedOrientation = value;
  }

  ngOnDestroy() {
    this._mediaQueryList.removeEventListener(
      'change',
      this._deviceOrientationChanged
    );
  }
}
