import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { Subscribable } from '../../base/subscribable';

export enum Layout {
  MOBILE,
  MOBILE_LANDSCAPE,
  TABLET,
  DESKTOP,
}

@Injectable({ providedIn: 'root' })
export class LayoutService extends Subscribable {
  private readonly LAYOUT_BREAKPOINTS_LIST = [
    { layout: Layout.MOBILE, breakpoints: Breakpoints.HandsetPortrait },
    {
      layout: Layout.MOBILE_LANDSCAPE,
      breakpoints: Breakpoints.HandsetLandscape,
    },
    { layout: Layout.TABLET, breakpoints: Breakpoints.Tablet },
    { layout: Layout.DESKTOP, breakpoints: Breakpoints.Web },
  ];

  private currentLayoutSubject: BehaviorSubject<Layout>;
  private isCurrentLayoutPortraitOrientation$ = new BehaviorSubject<boolean>(
    true
  );

  constructor(private readonly breakpointObserver: BreakpointObserver) {
    super();

    this.currentLayoutSubject = new BehaviorSubject(Layout.DESKTOP);
    this.initSubscriptions();
  }

  public getDevicePortraitOrientation(): Observable<boolean> {
    return this.isCurrentLayoutPortraitOrientation$
      .asObservable()
      .pipe(distinctUntilChanged());
  }

  public isMobile() {
    return this.currentLayoutSubject.asObservable().pipe(
      map(() => this.detectMobileBrowser()),
      distinctUntilChanged()
    );
  }

  public isMobilePortrait() {
    return this.isCurrentLayoutPortraitOrientation$.asObservable().pipe(
      map(v => v && this.detectMobileBrowser()),
      distinctUntilChanged()
    );
  }

  public isTablet() {
    return this.currentLayoutSubject.asObservable().pipe(
      map(v => v === Layout.TABLET),
      distinctUntilChanged()
    );
  }

  public isDesktop() {
    return this.currentLayoutSubject.asObservable().pipe(
      map(() => !this.detectMobileBrowser()),
      distinctUntilChanged()
    );
  }

  private initSubscriptions() {
    this.LAYOUT_BREAKPOINTS_LIST.forEach(v => {
      const subscription = this.breakpointObserver
        .observe(v.breakpoints)
        .pipe(filter(view => view.matches))
        .subscribe(() => {
          this.isCurrentLayoutPortraitOrientation$.next(
            window.matchMedia('(orientation: portrait)')?.matches
          );
          this.currentLayoutSubject.next(v.layout);
        });
      this.addSubscription(subscription);
    });
  }

  private detectMobileBrowser() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some(toMatchItem => {
      return navigator.userAgent.match(toMatchItem);
    });
  }
}
