import {
  AfterViewInit,
  Directive,
  HostBinding,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscribable } from './subscribable';

@Directive()
export abstract class BaseComponent
  extends Subscribable
  implements OnInit, OnDestroy, AfterViewInit
{
  @HostBinding('style.display') display = 'block';
  @HostBinding('style.width') width = '100%';
  @HostBinding('style.height') height = '100%';

  public ngOnInit() {
    this.onInit();
  }

  public ngAfterViewInit() {
    this.onAfterViewInit();
  }

  public override ngOnDestroy() {
    super.ngOnDestroy();
    this.onDestroy();
  }

  protected onInit() {
    // virtual method
  }

  protected onAfterViewInit() {
    // virtual method
  }

  protected onDestroy() {
    // virtual method
  }
}
