<div *ngIf="shouldDisplayContainer$ | async" class="fixed inset-0 z-[101]">
  <div
    class="flex w-full h-full justify-center items-center flex-col bg-gradient-to-r from-[#aa70d4] to-[#4623a0] gap-8">
    <img
      [src]="
        'assets/images/device-orientation/to-' +
        this.requiredOrientation +
        '.svg'
      "
      alt="device-orientation" />

    <div
      class="flex justify-center items-center flex-col text-white gap-1 uppercase">
      <span appTranslation="ROTATION.PORTRAIT.LABEL" class="text-sm"></span>
      <span
        [appTranslation]="
          this.requiredOrientation === 'portrait'
            ? 'ROTATION.PORTRAIT.TEXT'
            : 'ROTATION.LANDSCAPE.TEXT'
        "
        class="text-lg"></span>
    </div>
  </div>
</div>
