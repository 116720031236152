import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { HttpSignUpService, StorageService } from '@common-ui/emeraude-shared';

@Injectable()
export class SignUpDeactiveGuard implements CanDeactivate<void> {
  constructor(
    private readonly storageService: StorageService,
    private readonly httpSignUpService: HttpSignUpService
  ) {}

  async canDeactivate(
    component: void,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    const email = `test_${Date.now()}@emeraude.games`;
    const username = 'test';
    try {
      const tokens = await this.httpSignUpService
        .signUp({ email, username, password: null })
        .toPromise();
      this.storageService.token.set(tokens.token);
      this.storageService.refreshToken.set(tokens.refreshToken);
    } catch (error) {
      console.error(error);
    }
    return true;
  }
}
