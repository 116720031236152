import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { HttpUserService, StorageService } from '@common-ui/emeraude-shared';

@Injectable()
export class LanguageSelectDeactiveGuard implements CanDeactivate<void> {
  constructor(
    private readonly storageService: StorageService,
    private readonly httpUserService: HttpUserService
  ) {}

  async canDeactivate(
    component: void,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ) {
    const language = this.storageService.language.get();
    try {
      await this.httpUserService.update({ language }).toPromise();
    } catch (error) {
      console.error(error);
    }
    return true;
  }
}
