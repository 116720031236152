import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmeraudeIconComponent } from '@common-ui/emeraude-icons';
import { SpinnerService } from '@common-ui/emeraude-shared';
import { Observable, map, take, tap, filter, BehaviorSubject } from 'rxjs';
import { FooterLogoComponent } from '@common-ui/emeraude-components';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'thales-365-spinner',
  standalone: true,
  imports: [CommonModule, EmeraudeIconComponent, FooterLogoComponent],
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  isShowSpinner$: Observable<boolean>;
  isFirstLoad$ = new BehaviorSubject<boolean>(true);

  constructor(private spinnerService: SpinnerService) {
    this.isShowSpinner$ = this.spinnerService.getSpinnerObserver().pipe(
      distinctUntilChanged(),
      map(status => status === 'start')
    );

    this.spinnerService
      .getSpinnerObserver()
      .pipe(
        distinctUntilChanged(),
        filter(status => status === 'stop'),
        take(1)
      )
      .subscribe(() => this.isFirstLoad$.next(false));
  }
}
