<div class="button__container" [ngClass]="[theme, size]">
  <div
    class="button bg-gradient-to-r from-[#1cb3ff] to-[#b03f92] rounded-base p-[1px]">
    <button [type]="type" [disabled]="disabled" (click)="handleClickButton()">
      <ng-container *ngIf="icon && iconPosition === 'left'">
        <span class="icon" [emeraude-icon]="icon"></span>
      </ng-container>

      <span>
        <ng-content></ng-content>
      </span>

      <ng-container *ngIf="icon && iconPosition === 'right'">
        <span class="icon" [emeraude-icon]="icon"></span>
      </ng-container>
    </button>
  </div>
</div>
