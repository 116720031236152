<div
  class="page-overlay-wrapper flex justify-between items-center"
  [ngStyle]="
    (isFirstLoad$ | async)
      ? {
          background:
            'linear-gradient(to right, #aa70d4, #a569cc, #9756b8, #8841a1, #4623a0)'
        }
      : { 'background-image': 'url(assets/common/main-bg.jpg)' }
  "
  *ngIf="isShowSpinner$ | async">
  <div [class.dotted-border]="isFirstLoad$ | async" class="out">
    <img
      [ngStyle]="
        (isFirstLoad$ | async) === false ? { opacity: 1 } : { opacity: 0 }
      "
      src="assets/images/spinner/spinner.png"
      alt="" />
  </div>
</div>
