/* eslint-disable max-len */
export const assets = {
  COMMON_BG: 'assets/common/main-bg.jpg',
  NAVIGATION_BACK_URL: 'assets/images/permanents/back-button/back-button.svg',
  SPINNER: 'assets/images/spinner/spinner.png',
  EPISODE_SAMPLE: 'assets/images/select-episode/episode-sample.png',
  PERMANENT_BACK_BTN: 'assets/images/permanents/back-button/back-button.svg',
  PERMANENT_MENU_OPTION:
    'assets/images/permanents/menu-options/menu-option.svg',
  PERMANENT_MENU_OPTION_CLICK:
    'assets/images/permanents/menu-options/menu-option-click.svg',
  PERMANENT_MENU_OPTION_QUIT: 'assets/images/permanents/menu-options/quit.svg',
  TO_LANDSCAPE: 'assets/images/device-orientation/to-landscape.svg',
  TO_PORTRAIT: 'assets/images/device-orientation/to-portrait.svg',
  LOGO: 'assets/logo/logo.svg',
  STAR_FILL: 'assets/icons/rating/star_fill.svg',
  STAR_OUTLINED: 'assets/icons/rating/star_outlined.svg',
};
