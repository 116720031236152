import { Injectable } from '@angular/core';

import {
  HttpScoreService,
  HttpUserService,
  SpinnerService,
  StorageService,
  User,
} from '@common-ui/emeraude-shared';
import { finalize, of, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SelectEpisodeService {
  constructor(
    private readonly httpUserService: HttpUserService,
    private spinnerServ: SpinnerService,
    private httpScoreService: HttpScoreService,
    private storageServ: StorageService
  ) {}

  public updateUser(data: Partial<User>, manualSpinner: boolean = false) {
    if (!manualSpinner) {
      this.spinnerServ.requestStarted();
    }

    return this.httpUserService.update(data).pipe(
      finalize(() => {
        if (!manualSpinner) {
          this.spinnerServ.requestEnded();
        }
      })
    );
  }

  public getUserEpisodeData() {
    const user = this.storageServ.user.get();

    this.spinnerServ.requestStarted();

    return (user && user._id ? of(user) : this.httpUserService.getById())
      .pipe(
        switchMap(user => {
          return this.httpScoreService.getUserScore(user._id, {
            params: { option: 'ALL' },
          });
        })
      )
      .pipe(
        finalize(() => {
          this.spinnerServ.requestEnded();
        })
      );
  }

  public getUserData() {
    return this.httpUserService.getById();
  }
}
