<div
  class="outdated-version-container bg-[url('/assets/common/main-bg.jpg')] bg-cover bg-no-repeat"
  [class.is-visible]="isAppVersionChanged$ | async">
  <emeraude-form-layout [class.mobile]="layoutService.isMobile() | async">
    <div body>
      <div class="body">
        <div class="title">
          <img [src]="logoImg" [alt]="logoImg" />
          <h1 appTranslation="SELECT_LANGUAGE.TITLE"></h1>
          <ng-container *ngIf="(layoutService.isMobile() | async) === false">
            <h4 appTranslation="SELECT_LANGUAGE.SUBTITLE"></h4>
          </ng-container>
        </div>

        <div class="outdated-version">
          <div
            class="description"
            appTranslation="GLOBAL.VERSION.OUTDATED.LABEL"></div>

          <emeraude-button (clicked)="location.reload()">
            <span
              appTranslation="GLOBAL.VERSION.OUTDATED_SHORT.BUTTON.LABEL"
              class="mobile-display"></span>
            <span
              appTranslation="GLOBAL.VERSION.OUTDATED.BUTTON.LABEL"
              class="desktop-display"></span>
          </emeraude-button>
        </div>
      </div>
    </div>
  </emeraude-form-layout>
</div>
