import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SelectEpisodeService } from '../../components/select-episode/select-episode.service';

export const EndOfEpisodeResolver: ResolveFn<boolean> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  selectEpisodeService: SelectEpisodeService = inject(SelectEpisodeService)
): Observable<boolean> => {
  const { nextScene, nextEpisode } = route.queryParams;
  if (!nextScene || !nextEpisode) {
    return of(false);
  }
  return selectEpisodeService
    .getUserEpisodeData()
    .pipe(
      map(
        data =>
          !data.items.find(episodeData => episodeData.episode === nextEpisode)
      )
    );
};
