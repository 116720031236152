<router-outlet></router-outlet>

<ng-container *ngIf="orientation">
  <thales-365-device-orientation-match [requiredOrientation]="orientation">
  </thales-365-device-orientation-match>
</ng-container>

<emeraude-outdated-version></emeraude-outdated-version>

<thales-365-spinner></thales-365-spinner>
