import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
  provideHttpClient,
} from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  importProvidersFrom,
} from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  ActivationEnd,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterModule,
  provideRouter,
} from '@angular/router';

import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { TurnPortraitComponent } from '@common-ui/emeraude-components';
import { provideSelectEpisodeConfig } from '@common-ui/emeraude-game-setup';
import { EmeraudeIconDirectiveModule } from '@common-ui/emeraude-icons';
import { provideLeaderBoardConfig } from '@common-ui/emeraude-leader-board';
import {
  provideCongratulationsConfig,
  provideRatingConfig,
} from '@common-ui/emeraude-out-boarding';
import { provideSelectLanguageConfig } from '@common-ui/emeraude-select-language';
import {
  ApplicationService,
  SpinnerService,
  StorageService,
  provideHttpConfig,
  provideRedirectorConfig,
} from '@common-ui/emeraude-shared';
import { provideSsoLoginConfig } from '@common-ui/emeraude-sign-in';

import { provideSignUpConfig } from '@common-ui/emeraude-sign-up';
import { AnimationLoader, provideLottieOptions } from 'ngx-lottie';
import { debounceTime, filter, fromEvent, switchMap, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { appConstant } from './app.constant';
import { appRoutes } from './app.routes';
import { AppService } from './app.service';
import { LanguageSelectDeactiveGuard } from './auth/language-select-deactive.guard';
import { SignUpDeactiveGuard } from './auth/sign-up-deactive.guard';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { AppVersionCheckService } from './components/emeraude-outdated-version/app-version-check.service';
import { EmeraudeOutdatedVersionComponent } from './components/emeraude-outdated-version/emeraude-outdated-version.component';
import {
  HttpErrorInterceptor,
  HttpRequestInterceptor,
} from './core/interceptor';
import { NewLottieModule } from './components/new-lottie/new-lottie.module';
import { IAppConfig } from './interfaces';
import { PreloadingService } from './preloading/services/preloading.service';
import {
  DeviceOrientationMatchComponent,
  ViewportOrientation,
} from './shared/components/device-orientation-match/device-orientation-match.component';
import { provideAppConfig } from './shared/config/config.di';
import { UserProfileResolver } from './shared/resolvers/user-profile.resolver';
import { getEpisodeList } from './configs/episodes.config';
import { LANGUAGE_LIST } from './configs/languages.config';
import { LayoutService } from './shared/service/layout.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    TranslateModule,
    EmeraudeIconDirectiveModule,
    SpinnerComponent,

    TurnPortraitComponent,
    DeviceOrientationMatchComponent,
    NewLottieModule,
    EmeraudeOutdatedVersionComponent,
  ],
  providers: [AppVersionCheckService],
})
export class AppComponent implements OnInit {
  @HostBinding('style.background-image') bgImg: string;

  title = 'emeraude-ui';

  orientation: ViewportOrientation = null;

  routeData = null;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly storageService: StorageService,
    private readonly layoutService: LayoutService,
    private readonly spinnerService: SpinnerService,
    private readonly preloadingService: PreloadingService,
    private readonly appService: AppService
  ) {
    this.attachData();
    this.updateAppStyle();
  }

  static bootstrap(appConfig: IAppConfig) {
    return bootstrapApplication(this, {
      providers: [
        ApplicationService,
        SpinnerService,
        PreloadingService,
        SignUpDeactiveGuard,
        LanguageSelectDeactiveGuard,
        UserProfileResolver,

        /**
         * provide app config to use DI in angular app
         * @see https://dev.to/this-is-angular/angular-15-what-happened-to-environmentts-koh
         * */

        provideLottieOptions({
          player: () => import('lottie-web'),
        }),
        AnimationLoader,

        provideAppConfig(appConfig),
        provideRouter(appRoutes),
        provideHttpClient(),
        provideHttpConfig({
          url: environment.url,
          apiUrl: environment.apiUrl,
        }),

        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpRequestInterceptor,
          multi: true,
        },

        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi: true,
        },

        importProvidersFrom(
          TranslateModule.forRoot({
            loader: {
              provide: TranslateLoader,
              useFactory: HttpLoaderFactory,
              deps: [HttpClient],
            },
            defaultLanguage: appConstant.defaultLanguage || 'en',
          })
        ),

        provideRedirectorConfig({
          active: true,
          startAt: appConstant.startAt,
        }),

        provideSsoLoginConfig({
          ssoLoginUrl: '/select-language',
        }),

        provideSignUpConfig({}),

        provideSelectLanguageConfig({
          languages: LANGUAGE_LIST,
          nextPath: 'select-episode',
        }),

        provideSelectEpisodeConfig({
          nextPath: '/game',
          episodeList: getEpisodeList(location.search?.includes('locked=true')),
        }),

        provideLeaderBoardConfig({
          nextPath: '/select-episode',
        }),

        provideRatingConfig({
          nextPath: '/end-of-episode',
        }),

        provideCongratulationsConfig({
          nextPath: '/rating',
          yourTimeVisible: false,
        }),

        provideAnimations(),
      ],
    }).catch(err => console.error(err));
  }

  ngOnInit() {
    this.initData();
    this.initSubscriptions();
    this.preloadingService.loadByPatterns();
  }

  private attachData() {
    const app = {
      router: this.router,
    };
    globalThis.app = app;
  }

  private updateAppStyle() {
    const { style } = document.documentElement;
    style.setProperty('--app-width', `${window.innerWidth}px`);
    style.setProperty('--app-height', `${window.innerHeight}px`);
  }

  private initData() {
    const language =
      this.storageService.language.get() || this.translateService.defaultLang;
    this.translateService.setDefaultLang(language);
  }

  private initSubscriptions() {
    fromEvent(window, 'resize')
      .pipe(
        debounceTime(100),
        tap(() => {
          this.updateAppStyle();
        })
      )
      .subscribe();

    this.router.events.subscribe(ev => {
      // console.debug(ev);
      if (ev instanceof NavigationStart) {
        this.spinnerService.requestStarted();
      }
      if (ev instanceof NavigationEnd) {
        this.spinnerService.requestEnded();
      }
    });

    this.appService.getBgImgObs().subscribe(value => {
      this.bgImg = value;
    });

    this.router.events
      .pipe(
        tap(ev => {
          if (ev instanceof ActivationEnd) {
            const data = ev.snapshot.routeConfig.data;
            this.routeData = data || this.routeData;
          }
        })
      )
      .pipe(
        filter(ev => ev instanceof ActivationEnd),
        switchMap(() => this.layoutService.isDesktop()),
        tap(value => {
          if (value) {
            this.orientation = null;
            return;
          }
          this.orientation = this.routeData?.['orientation'];
        })
      )
      .subscribe();
  }
}
