import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  BehaviorSubject,
  interval,
  of,
  startWith,
  switchMap,
  takeUntil,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import { IGitRevision } from './git-revision.interface';
import { GIT_REVISION_KEY } from './git-revision.const';

@Injectable()
export class AppVersionCheckService {
  public isAppVersionChanged$ = new BehaviorSubject<boolean>(
    location.search?.includes('versionCheck=true')
  );
  private gitRevision: string = localStorage.getItem(GIT_REVISION_KEY);

  constructor(private httpClient: HttpClient) {
    this.checkVersion();
  }

  public checkVersion() {
    interval(15000)
      .pipe(
        startWith(0),
        switchMap(() =>
          this.httpClient.get(
            `${environment.url}/assets/jsons/version-info.json?t=${Date.now()}`
          )
        ),
        takeUntil(this.isAppVersionChanged$.asObservable()),
        switchMap(({ gitRevision }: IGitRevision) => {
          localStorage.setItem(GIT_REVISION_KEY, gitRevision);

          if (!!this.gitRevision && this.gitRevision !== gitRevision) {
            this.isAppVersionChanged$.next(true);
            return this.httpClient.get(
              `${
                environment.url
              }/assets/jsons/version-info.json?t=${Date.now()}&clearCache=true`
            );
          }
          this.gitRevision = gitRevision;

          return of(gitRevision);
        })
      )
      .subscribe();
  }
}
