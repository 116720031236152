import { Route } from '@angular/router';
import { LanguageSelectDeactiveGuard } from './auth/language-select-deactive.guard';
import { SignUpDeactiveGuard } from './auth/sign-up-deactive.guard';
import { UserProfileResolver } from './shared/resolvers/user-profile.resolver';
import { EndOfEpisodeResolver } from './shared/resolvers/end-of-episode.resolver';

export const appRoutes: Route[] = [
  {
    path: '',
    children: [
      {
        path: 'select-language',
        loadComponent: () =>
          import(
            './components/emeraude-emeraude-select-language/emeraude-select-language.component'
          ).then(m => m.EmeraudeSelectLanguageComponent),
        canDeactivate: [LanguageSelectDeactiveGuard],
      },
      {
        path: 'sign-in',
        loadComponent: () =>
          import(
            './components/emeraude-sso-login/emeraude-sso-login.component'
          ),
        canDeactivate: [SignUpDeactiveGuard],
      },
      {
        path: 'select-episode',
        loadComponent: () =>
          import('./components/select-episode/select-episode.component'),
        resolve: { user: UserProfileResolver },
      },
      {
        path: 'intro',
        loadChildren: () =>
          import('./intro/intro.module').then(m => m.IntroModule),
        data: { orientation: null },
      },
      {
        path: 'game',
        loadChildren: () =>
          import('./game/game.module').then(m => m.GameModule),
        resolve: { user: UserProfileResolver },
      },
      {
        path: 'leader-board',
        loadComponent: () =>
          import('@common-ui/emeraude-leader-board').then(
            m => m.EmeraudeLeaderBoardComponent
          ),
      },
      {
        path: 'end-of-episode',
        loadComponent: () =>
          import('./components/end-of-episode/end-of-episode.component'),
        resolve: {
          isNextEpisodeEnabled: EndOfEpisodeResolver,
        },
      },
      {
        path: '**',
        redirectTo: 'sign-in',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'sign-in',
  },
];
