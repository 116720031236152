export const LANGUAGE_LIST: any[] = [
  {
    value: 'en',
    label: 'SELECT_LANGUAGE.LANGUAGE.ENGLISH',
    icon: 'ukFlag',
  },
  {
    value: 'fr',
    label: 'SELECT_LANGUAGE.LANGUAGE.FRENCH',
    icon: 'franceFlag',
  },
  {
    value: 'es',
    label: 'SELECT_LANGUAGE.LANGUAGE.SPAIN',
    icon: 'spainFlag',
  },
  // {
  //   value: 'de',
  //   label: 'SELECT_LANGUAGE.LANGUAGE.GERMANY',
  //   icon: 'germanyFlag',
  // },
  {
    value: 'it',
    label: 'SELECT_LANGUAGE.LANGUAGE.ITALIA',
    icon: 'italiaFlag',
  },
  // {
  //   value: 'pt-br',
  //   label: 'SELECT_LANGUAGE.LANGUAGE.PORTUGAL',
  //   icon: 'portugalFlag',
  // },
  // {
  //   value: 'nl',
  //   label: 'SELECT_LANGUAGE.LANGUAGE.NETHERLANDS',
  //   icon: 'netherlandsFlag',
  // },
  // {
  //   value: 'pl',
  //   label: 'SELECT_LANGUAGE.LANGUAGE.POLAND',
  //   icon: 'polandFlag',
  // },
  // {
  //   value: 'ro',
  //   label: 'SELECT_LANGUAGE.LANGUAGE.ROMANIA',
  //   icon: 'romaniaFlag',
  // },
  // {
  //   value: 'sk',
  //   label: 'SELECT_LANGUAGE.LANGUAGE.SLOVAKIA',
  //   icon: 'slovakiaFlag',
  // },
];
