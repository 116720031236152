import { Injectable, Optional } from '@angular/core';
import { SpinnerService } from '@common-ui/emeraude-shared';
import AsyncPreloader from 'async-preloader';

import { GameAnimationDataConstant } from '../../game/constants/game-animation-data.constant';
import { assets } from '../../../environments/assets';

@Injectable()
export class PreloadingService {
  private readonly loader = AsyncPreloader;

  constructor(
    @Optional()
    private readonly spinnerService?: SpinnerService
  ) {}

  public async loadByPatterns(): Promise<Blob[]> {
    this.spinnerService?.requestStarted?.();
    const urls = this.getUrls();
    try {
      const values = await this.loadFromWorker(urls);
      this.spinnerService?.requestEnded?.();
      return values;
    } catch (error) {
      console.error(error.message);
    }

    const values = await this.loadFromLoader(urls);
    this.spinnerService?.requestEnded?.();
    return values;
  }

  private getUrls() {
    const urls = [...this.getAssetUrls(), ...this.getAnimationUrls()];
    return urls;
  }

  private getAssetUrls() {
    const urls = Object.values(assets);
    return urls;
  }

  public getAnimationUrls() {
    const data = GameAnimationDataConstant;
    const urls = Object.values(data).map((v: any) => v.path);
    return urls;
  }

  private async loadJson(url: string) {
    try {
      const value: any = await this.loader.loadJson({ src: url });
      if (value.assets.length) {
        const urls: string[] = value.assets
          .filter(v => v.u && v.p)
          .map(v => url.substring(0, url.lastIndexOf('/') + 1) + v.u + v.p)
          .filter(v => v);
        await Promise.all(urls.map(v => this.loadUrl(v)));
      }
    } catch (error) {
      console.error(error.message);
    }
  }

  private async loadUrl(url: string): Promise<Blob> {
    try {
      if (url.endsWith('.json')) {
        await this.loadJson(url);
        return null;
      }
      const value = await this.loader.loadBlob({ src: url });
      return value;
    } catch (error) {
      console.error(error.message);
      return null;
    }
  }

  private async loadFromLoader(urls: string[]): Promise<Blob[]> {
    const values = await Promise.all(urls.map(url => this.loadUrl(url))).then(
      result => result.filter(v => v)
    );
    return values;
  }

  private async loadFromWorker(urls: string[]): Promise<Blob[]> {
    if (typeof Worker === 'undefined') {
      throw new Error('Worker unavailable');
    }

    return new Promise(resolve => {
      const worker = new Worker(
        new URL('/src/workers/preloading.worker', import.meta.url)
      );

      worker.addEventListener('message', ev => {
        resolve(ev.data);
      });

      worker.postMessage(urls);
    });
  }
}
