import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { IIconKey } from '@common-ui/emeraude-assets';
import { EmeraudeIconModule } from '@common-ui/emeraude-icons';

import { IButtonType } from './types';
import { BUTTON_TYPE } from '@common-ui/emeraude-components';

@Component({
  selector: 'emeraude-button',
  standalone: true,
  imports: [CommonModule, EmeraudeIconModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() type: IButtonType = 'button';

  @Input() theme = 'primary';

  @Input() size: 'small' | 'medium' | 'large' = 'medium';

  @Input() disabled = false;

  @Input() loading = false;

  @Input() icon: IIconKey;

  @Input() iconPosition: 'left' | 'right' = 'left';

  @Input() iconSize: 'small' | 'medium' | 'large' = 'medium';

  @Input() iconColor: 'primary' | 'secondary' = 'primary';

  @Input() iconOnlyTooltip: string;

  @Output() clicked = new EventEmitter<void>();

  // child reference

  @ViewChild('iconRef') iconRef: HTMLElement;

  // getter for the button type
  get buttonType() {
    return BUTTON_TYPE;
  }

  handleClickButton() {
    this.clicked.emit();
  }
}
