export const EPISODE_LIST = [
  {
    id: 'EPISODE1',
    labelI18n: 'SELECT_EPISODE.EPISODE_LIST.LABEL_EPISODE_1',
    descriptionI18n: 'SELECT_EPISODE.EPISODE_LIST.DESC_EPISODE_1',
    image: 'assets/images/select-episode/episode-sample.png',
    startDate: '2021-01-01T00:00:00.000Z',
  },
  {
    id: 'EPISODE2',
    labelI18n: 'SELECT_EPISODE.EPISODE_LIST.LABEL_EPISODE_2',
    descriptionI18n: 'SELECT_EPISODE.EPISODE_LIST.DESC_EPISODE_2',
    image: 'assets/images/select-episode/episode-sample.png',
    startDate: '2023-01-01T00:00:00.000Z',
  },
  {
    id: 'EPISODE3',
    labelI18n: 'SELECT_EPISODE.EPISODE_LIST.LABEL_EPISODE_3',
    descriptionI18n: 'SELECT_EPISODE.EPISODE_LIST.DESC_EPISODE_3',
    image: 'assets/images/select-episode/episode-sample.png',
    startDate: '2023-01-01T00:00:00.000Z',
  },
];

export function getEpisodeList(isTesting: boolean) {
  if (!isTesting) {
    return EPISODE_LIST;
  }

  return EPISODE_LIST.map(v => ({
    ...v,
    startDate: '2024-01-01T00:00:00.000Z',
  }));
}
